import React from 'react';
import Input from './Input';
import Form from './../Form';
import InputMask from 'react-input-mask';
import axios from "axios";
import Loading from "../Loading";

class InputCEP extends Input {

    constructor(props) {
        super(props)

        this.state.search = this.props.search || false;
        this.state.dataTeste = this.props.dataTeste || '';
        this.state.searching = false;
        this.handleChangeCep = this.handleChangeCep.bind(this);
        this.beforeMaskedValueChange = this.beforeMaskedValueChange.bind(this);
    }

    handleChangeCep = e => {
        if (this.state.search) {
            let cep = e.target.value.replace(/_/g, "").replace(/-/g, "");
            if (cep.length === 8) {
                this._searchAddress(cep);
            }
        }
        this.handleChange(e);
    }

    _searchAddress(cep) {
        if (cep && this.state.search && this.state.searching !== cep) {
            let callback = this.props.callback || function () {
            };
            this.setState({
                searching: cep
            });
            Loading.add();
            let error = {};
            error[this.state.name] = "CEP não encontrado!";
            axios.get("https://viacep.com.br/ws/" + cep + "/json").then(response => {
                let {data} = response;
                let search = this.state.search;
                Loading.remove();

                const cidadesValidas = [
                    {
                        cidade: 'Campinas',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Ribeirão Preto',
                        estado: 'SP'
                    },
                    {
                        cidade: 'São José do Rio Preto',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Niterói',
                        estado: 'RJ'
                    },
                    {
                        cidade: 'Rio de Janeiro',
                        estado: 'RJ'
                    },
                    {
                        cidade: 'São Paulo',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Itapecerica da Serra',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Barueri',
                        estado: 'SP'
                    },
                    {
                        cidade: 'São Bernardo do Campo',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Santo André',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Osasco',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Cotia',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Franco da Rocha',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Diadema',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Santana de Parnaíba',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Pirapora do Bom Jesus',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Guarulhos',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Vargem Grande Paulista',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Carapicuíba',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Ribeirão Pires',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Embu das Artes',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Mairiporã',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Mauá',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Caieiras',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Taboão da Serra',
                        estado: 'SP'
                    },
                    {
                        cidade: 'São Caetano do Sul',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Itapevi',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Embu-Guaçu',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Jandira',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Cajamar',
                        estado: 'SP'
                    },
                    {
                        cidade: 'São Lourenço da Serra',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Francisco Morato',
                        estado: 'SP'
                    },
                    {
                        cidade: 'São Bernardo do Campo',
                        estado: 'SP'
                    },
                    {
                        cidade: 'Rio Grande da Serra',
                        estado: 'SP'
                    }
                ]

                const valid = cidadesValidas.find((item) => item.cidade === data.localidade && item.estado === data.uf)

                if (!valid) {
                    error[this.state.name] = "Este CEP não se encontra na nossa base, entre em contato com nosso SAC: 4003-5323(Capitais) ou 0800-014-7720 (Demais Localidades).";
                    for (let i in search) {
                        let element = document.querySelector('[name="' + i + '"]');
                        if (element) {
                            element.setValue('');
                        }
                    }
                }

                if (!data.erro && valid) {
                    for (let i in search) {
                        let element = document.querySelector('[name="' + i + '"]');
                        if (element && data.hasOwnProperty(search[i])) {
                            element.setValue(data[search[i]]);
                        }
                    }
                } else {
                    Form.showErrors(error);
                }
                callback(data);
            }).catch(() => {
                callback({erro: error});
                Form.showErrors(error);
                Loading.remove();
            });
        }
    }

    beforeMaskedValueChange = (newState, oldState, userInput) => {
        var {value} = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = {start: cursorPosition, end: cursorPosition};
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    }

    render() {
        return (
            <div ref={el => this.input_cep = el || this.input_cep}
                 className={this.state.className.join(" ") + (this.state.isset ? " isset" : "")}>
                {!this.state.load ? (
                    <div>
                        <InputMask readOnly={this.state.readonly} ref={el => this.input = el || this.input}
                                   mask="99999-999"
                                   data-teste={this.state.dataTeste} maskChar={null}
                                   beforeMaskedValueChange={this.beforeMaskedValueChange} type={this.state.type}
                                   id={this.state.id} name={this.state.name}
                                   className={(!this.state.label ? "no-label" : "")}
                                   onChange={(e) => this.handleChangeCep(e)} defaultValue={this.state.value}/>
                        {this.state.label ? (
                            <label htmlFor={this.state.id}>{this.state.label}</label>
                        ) : ""}
                    </div>
                ) : this.loader()}
            </div>
        )
    }
}

export default InputCEP;
