import React, { Component } from 'react'
import Grid from "../modules/Grid";
import { Form, Input, Select, Radio, InputCPF, InputCNPJ, InputPhone, Textarea, File } from "../../utils/Form";
import Loading from "../../utils/Loading";
import alertMessageActions from '../../actions/alertMessage';
import utilsActions from '../../actions/utils';
import store from '../../store';
import { connect } from "react-redux";
import env from "../../env";
import { URLSearchParams } from 'core-js/modules/web.url-search-params';

class Formulario extends Component {
    state = {
        pessoa: false,
        success: false,
        assuntos: {},
        motivos:{},
        pj_enabled: true,
        motivo: false,
        email_readonly: false,
        assunto: null,
        mensagem: null,
    }

    componentDidMount() {
        this.props.dispatch(utilsActions.getAssuntosFaleConosco());
        this.props.dispatch(utilsActions.getMotivosCancelamento());
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.assuntos) {
            this.setState({
                assuntos: nextProps.assuntos
            });
        }

        if (nextProps.motivos) {
            this.setState({
                motivos: nextProps.motivos
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.pessoa) {
            if (this.props.usuario.cpf) {
                this.setState({
                    pessoa: 'fisica'
                });
            }

            if (this.props.usuario.cnpj) {
                this.setState({
                    pessoa: 'juridica'
                });
            }
        }
    }

    sendMessage() {
        let form = document.getElementById('form-fale-conosco');
        if (form) {
            let bodyFormData = new FormData(form);
            let getMensagem = "";

            if(document.getElementsByName('mensagem')[0]) {
                getMensagem = document.getElementsByName('mensagem')[0].value;
                bodyFormData.append('mensagem', getMensagem);
            } else {
                getMensagem = "Atualização de Dados Pessoais";
                bodyFormData.append('mensagem', getMensagem);
            }

            bodyFormData.append('assinatura', this.props.assinatura_selecionada);

            try {
                if (window.location.href.indexOf('to_email=')) {
                    var url = new URL(window.location.href);
                    var to_email = url.searchParams.get("to_email");
                    if (to_email) {
                        bodyFormData.append('to_email', to_email);
                    }
                }
            } catch (e) { }

            Loading.add();
            store.dispatch(alertMessageActions.removeAlert());

            Form.send("/utils/enviar-fale-conosco", bodyFormData).then((response) => {
                Loading.remove();
                window.scrollTo(0, 0);
                let { data, status } = response;
                if (status === 200) {
                    if (this.props.usuario.has_cookie) {
                        store.dispatch(alertMessageActions.setAlert('green', data.message || 'E-mail enviado com sucesso!'));
                        store.dispatch({ type: "REDIRECT", redirect: "/" });
                    } else {
                        this.setState({
                            success: true
                        });
                    }
                    return;
                } else if (data.error) {
                    let message = data.error.message || "Ops algo deu errado!";
                    Form.showErrors(message);
                }else if(typeof data.message !== "undefined" && status !== 200){
                    store.dispatch(alertMessageActions.setAlert('red', data.message));
                }
            }).catch((e) => {
                Loading.remove();
            });
        }
    }

    changePessoa(e) {
        this.setState({
            pessoa: e.target.value
        });
    }

    changeAssunto(e) {
        let assuntoId = parseInt(e.target.value);
        this.setState({
            motivo: false
        })
        if (assuntoId === 31) { // Assunto: Privacidade de Dados
            this.validaUsuarioLogadoPrivacidadeDados();

            this.setState({
                pj_enabled: false,
                email_readonly: true,
            })
        }else if(assuntoId === 28){ // Assunto: Cancelamento de assinatura
            
            this.setState({
                motivo: true
            })
        }else if(assuntoId === 2){ // Assunto: Cancelamento de assinatura
            
            this.setState({
                assunto: 2
            })
        }else{
            this.setState({
                pj_enabled: true,
                email_readonly: false,
            })
        }
        this.setState({ assunto: assuntoId })
        return assuntoId
    }
    validaUrl(url){
        try{
            new URL(url);
            return true;
        }catch (e){
            return false
        }
    }

    validaUsuarioLogadoPrivacidadeDados() {
        let milliseconds = 6000;

        if (this.props.usuario.email.length < 1) {
            let msg = 'Por questão de <b>segurança</b>, para encaminhamento da sua solicitação por este canal é necessário estar <b>logado</b>.'
            + ' Caso não possua cadastro, você será direcionado para fazê-lo.';
            store.dispatch(alertMessageActions.setAlert('yellow', msg, milliseconds));
            setTimeout(()=>{
                window.location = env.URL_ACESSO+"/login?r="+window.location.href;
            }, milliseconds)
        }
    }

    render() {
        let usuario = this.props.usuario;
        let assuntos = this.state.assuntos;
        let motivos = this.state.motivos;
        let currentUrl = this.props.currentURL.split('/');
        let assunto = this.state.assunto;
        let mensagem = this.state.mensagem;

        const query = new URLSearchParams(window.location.search);

        if (query.get('privacidade-dados') === 'true') {
            assunto = '31';
            mensagem = 'Olá! Sou usuário do app e gostaria de pedir a exclusão de todos os meus dados pessoais da base do Estadão.';
            this.validaUsuarioLogadoPrivacidadeDados();
        }

        if(currentUrl[1] === 'fale-conosco'){
            if(typeof currentUrl[2] !== 'undefined'){
               switch (currentUrl[2]){
                   case 'redes-sociais':
                       assuntos  = {32:'Redes sociais - casos críticos'};
                       assunto = "32";
                       break;
                   case 'correcao-estadao':
                       assuntos = {33:'Correção Estadão'}
                       assunto = "33";
                       if(query.has('url') && this.validaUrl(query.get('url'))){
                           mensagem = `Gostaria de solicitar correção na matéria: ${query.get('url')}`
                       }
                       break;
                   default:
                       delete assuntos[32];
                       delete assuntos[33];
                       break;
               }
            }else{
                delete assuntos[32];
                delete assuntos[33];
            }
        }
        return !this.state.success ? (
            <div>
                <div className="block-single">
                    <form id="form-fale-conosco" action="" method="POST" className="content-form">
                        <div className="row">
                            <Grid cols="12 12 8 6 6">
                                <Select name="assunto" label="* O que você deseja?" value={assunto} data={assuntos} onChange={(e) => {
                                    this.changeAssunto(e);
                                }} />
                            </Grid>
                            {this.state.motivo === true ? (
                                <Grid cols="12 12 8 6 6">
                                    <Select name="motivo" label="* Motivo" value={this.state.value} data={motivos} />
                                </Grid>                            
                            ):''}
                        </div>
                        <div className="row">
                            <Grid cols="12 6 4 3 3">
                                <Radio name="pessoa" value="fisica" checked={usuario.cpf ? true : false} label="Pessoa física" onClick={(e) => {
                                    this.changePessoa(e);
                                }} />
                            </Grid>
                            <Grid cols="12 6 4 3 3">
                                <Radio name="pessoa" value="juridica" checked={usuario.cnpj ? true : false} label="Pessoa jurídica" onClick={(e) => {
                                    this.changePessoa(e);
                                }} readonly={this.state.pj_enabled === false} />
                            </Grid>
                        </div>

                        {this.state.pessoa === 'fisica' ? (
                            <div className="row">
                                <Grid cols="12 12 6 6 6">
                                    <Input type="text" name="nome" value={usuario.nome ? [usuario.nome, usuario.sobrenome].join(" ") : ""} label="* Nome Completo" />
                                </Grid>
                                <Grid cols="12 12 6 6 6">
                                    <InputCPF type="text" name="documento" value={usuario.cpf} label="* CPF" />
                                </Grid>
                            </div>
                        ) : this.state.pessoa === 'juridica' && (
                            <div className="row">
                                <Grid cols="12 12 6 6 6">
                                    <Input type="text" name="nome" label="* Razão Social" />
                                </Grid>
                                <Grid cols="12 12 6 6 6">
                                    <InputCNPJ type="text" name="documento" label="* CNPJ"/>
                                </Grid>
                            </div>
                        )}

                        <div className="row">
                            <Grid cols="12 12 12 4 4">
                                <Input type="text" name="email" readonly={this.state.email_readonly === true} value={usuario.email} label="* E-mail" />
                            </Grid>
                            <Grid cols="12 6 6 4 4">
                                <InputPhone type="text" name="telefone" value={[usuario.ddd_tel, usuario.telefone].join("")} label="Telefone residencial" />
                            </Grid>
                            <Grid cols="12 6 6 4 4">
                                <InputPhone type="text" name="celular" value={[usuario.ddd_cel, usuario.celular].join("")} label="Telefone celular (WhatsApp)" />
                            </Grid>
                        </div>

                        <div className="row">
                            <Grid cols="12 12 8 6 6">
                                <File name="arquivo" label="Enviar uma imagem, até (2MB):"/>
                            </Grid>
                        </div>                            
                        {this.state.assunto != '2' ? (
                            <div className="row">
                                <Grid cols="12">
                                    <Textarea type="text" name="mensagem" label="Detalhe o que você deseja:" value={mensagem} />
                                </Grid>
                            </div>
                        ): this.state.assunto === '2' && (
                            <div className="row" type="hidden">
                            </div>
                        )}
                    </form>
                </div>

                <button className="btn blue salvar-dados" data-dtkey="salvar_dados" onClick={() => { this.sendMessage() }}><i className="icon-paper-plane" /> Enviar</button>
            </div>
        ) : (
                <div className="success-message">
                    <i className="icon-ok-circled" /> Enviado com sucesso.
                </div>
            )
    }
}

const mapStateToProps = state => {
    return {
        usuario: state.user,
        assuntos: state.utils.assuntos_fale_conosco,
        motivos: state.utils.motivos_cancelamento,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        currentURL : state.currentURL,
    }
}

export default connect(mapStateToProps)(Formulario)
